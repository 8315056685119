import { securedWrap } from '@mop/shared/utils/securedWrap';
import { loadScript } from '@mop/shared/utils/util';
import type {
  AddMarkerEventHandler,
  MapConfig,
  Google,
  Marker,
  GoogleMap,
  Bounds,
  MarkerPosition,
  MarkerImage,
  GoogleMapMarkerModel,
  Coordinates,
} from '@mop/ui/types/googleMap';

const MARKER: Record<string, number> = {
  WIDTH_NORMAL: 30,
  HEIGHT_NORMAL: 35,
  WIDTH_MIDDLE: 34,
  HEIGHT_MIDDLE: 38,
  WIDTH_BIG: 68,
  HEIGHT_BIG: 76,
};

// Creating a Promise of the google instance to wait for initialization
let resolved: Function;
const googlePromise: Promise<Google> = new Promise((resolve) => {
  resolved = resolve;
});
const GOOGLE_CALLBACK = 'googleMapsCallback';
let map: GoogleMap = null;
let googleMarkers: Marker[] = [];
let currentPopup: any;

export default function useUiGoogleMapClient(apiKey: string, mapConfig: MapConfig) {
  const { language, region, countryCenterPoint, mapStyle } = mapConfig;

  function initialize() {
    if (window.google) {
      return googlePromise;
    }

    (window as any)[GOOGLE_CALLBACK] = () => resolved();
    const scriptSource = `https://maps.googleapis.com/maps/api/js?callback=${GOOGLE_CALLBACK}&key=${apiKey}&language=${language}&region=${region}`;
    loadScript({ source: scriptSource });

    return googlePromise;
  }

  function createMap(element: HTMLElement) {
    map = new window.google.maps.Map(element, {
      zoom: 6,
      center: countryCenterPoint,
      styles: mapStyle,
    });
  }

  function addMarkers(
    mopMarkers: GoogleMapMarkerModel[],
    selectedMopMarkerId: string | undefined,
    clickHandler: AddMarkerEventHandler,
  ) {
    mopMarkers.forEach((mopMarker: GoogleMapMarkerModel) => {
      const coordinates: Coordinates = mopMarker.getCoordinates();
      const googleMarker: Marker = new window.google.maps.Marker({
        position: {
          lat: coordinates.latitude,
          lng: coordinates.longitude,
        },
        map,
        optimized: false,
      });

      googleMarker.addListener('click', () => {
        clickHandler(mopMarker, googleMarker);
        if (mopMarker.getPopupContent()) {
          if (currentPopup) {
            currentPopup.close();
          }
          currentPopup = new window.google.maps.InfoWindow({
            content: mopMarker.getPopupContent(),
          });
          currentPopup.open({
            anchor: googleMarker,
            map,
            shouldFocus: false,
          });
        }
      });

      googleMarker.mopMarkerId = mopMarker.getId();
      googleMarker.mopMarker = mopMarker;

      googleMarker.setIcon(
        createMarkerImage(googleMarker, MARKER.WIDTH_NORMAL, MARKER.HEIGHT_NORMAL, selectedMopMarkerId),
      );

      googleMarkers.push(googleMarker);
      googleMarker.setMap(map);
    });
  }

  function dispose() {
    map = null;
    googleMarkers = [];
  }

  function updateMarker(mopMarker: GoogleMapMarkerModel, selectedMopMarkerId: string | undefined) {
    if (!mopMarker) {
      return;
    }

    googleMarkers.forEach((googleMarker) => {
      const defaultImage: MarkerImage = createMarkerImage(
        googleMarker,
        MARKER.WIDTH_NORMAL,
        MARKER.HEIGHT_NORMAL,
        selectedMopMarkerId,
      );
      googleMarker.setIcon(defaultImage);
    });

    const selectedGoogleMarker: Marker = googleMarkers.find(
      (googleMarker) => googleMarker.mopMarkerId === mopMarker.getId(),
    );
    const selectedMarkerImage: MarkerImage = createMarkerImage(
      selectedGoogleMarker,
      MARKER.WIDTH_BIG,
      MARKER.HEIGHT_BIG,
      selectedMopMarkerId,
    );
    selectedGoogleMarker.setIcon(selectedMarkerImage);
    const coordinates: Coordinates = mopMarker.getCoordinates();
    map.setCenter({
      lat: coordinates.latitude,
      lng: coordinates.longitude,
    });
  }

  function centerMapAroundMarkers() {
    const boundSpace = 0.01;
    const bounds: Bounds = new window.google.maps.LatLngBounds();

    // Extend bounds for each marker
    googleMarkers.forEach((marker: Marker) => {
      bounds.extend(marker.getPosition());
    });

    // If there is only 1 marker, the zoom level is too large
    if (googleMarkers.length === 1) {
      const markerPosition: MarkerPosition = googleMarkers[0].getPosition();
      const markerLatitude: number = markerPosition.lat();
      const markerLongitude: number = markerPosition.lng();

      // Extend the bounds by using a small offset
      bounds.extend({
        lat: markerLatitude + boundSpace,
        lng: markerLongitude + boundSpace,
      });
      bounds.extend({
        lat: markerLatitude - boundSpace,
        lng: markerLongitude - boundSpace,
      });
    }

    map.fitBounds(bounds);
    map.panToBounds(bounds);

    resizeGoogleMap(map);
  }

  function createMarkerImage(
    googleMarker: Marker | null,
    width: number,
    height: number,
    selectedMopMarkerId: string | undefined,
  ) {
    const image: any = {
      url: undefined,
      scaledSize: new window.google.maps.Size(width, height),
    };

    if (googleMarker.mopMarker && googleMarker.mopMarkerId === selectedMopMarkerId) {
      image.url = googleMarker.mopMarker.getSelectedIcon();
    } else if (googleMarker.mopMarker) {
      image.url = googleMarker.mopMarker.getIcon();
    }

    return image;
  }

  function cleanupMap() {
    googleMarkers.forEach((marker) => marker.setMap(null));
    googleMarkers = [];

    if (!map) {
      return;
    }

    map.setZoom(6);
    map.setCenter(countryCenterPoint);
  }

  function resizeGoogleMap(map: GoogleMap) {
    window.google.maps.event.trigger(map, 'resize');
  }

  return securedWrap({
    initialize,
    createMap,
    cleanupMap,
    addMarkers,
    centerMapAroundMarkers,
    updateMarker,
    dispose,
  });
}
